var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-alert',{attrs:{"outlined":"","color":"primary","icon":"mdi-information-outline","prominent":"","border":"left"}},[_vm._v(" Téléchargez le "),_c('a',{attrs:{"href":""},on:{"click":function($event){$event.preventDefault();return _vm.downloadReport.apply(null, arguments)}}},[_vm._v("rapport")]),_vm._v(" complet des progressions. ")]),_c('v-card',{staticClass:"pa-10",attrs:{"outlined":""}},[_c('v-card-title',[_c('v-text-field',{staticClass:"mb-6",attrs:{"append-icon":"mdi-magnify","label":"Rechercher","single-line":"","hide-details":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.users,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"locale":"fr-CH","search":_vm.search,"footer-props":{
        itemsPerPageOptions: [10, 30, 50],
        itemsPerPageText: 'Élements par page',
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"body",fn:function(ref){
      var items = ref.items;
return [_c('tbody',{staticClass:"text-left"},_vm._l((items),function(item){return _c('tr',{key:item._id},[_c('td',[_vm._v(_vm._s(item.lastname))]),_c('td',[_vm._v(_vm._s(item.firstname))]),_c('td',[_vm._v(_vm._s(item.email))]),_c('td',[_vm._v(" "+_vm._s(item.role === "employee" ? "Employé.e" : "Étudiant.e")+" ")]),_c('td',_vm._l((item.steps),function(step){return _c('v-icon',{key:((item._id) + "-slot-" + (step.number)),attrs:{"color":step.updatedAt && step.checked ? 'success' : 'grey'}},[_vm._v(_vm._s(("mdi-numeric-" + (step.number + 1) + "-circle-outline")))])}),1)])}),0)]}},{key:"footer.page-text",fn:function(ref){
      var pageStart = ref.pageStart;
      var pageStop = ref.pageStop;
      var itemsLength = ref.itemsLength;
return [_vm._v(_vm._s(pageStart)+"-"+_vm._s(pageStop)+" de "+_vm._s(itemsLength))]}}])})],1),_c('v-card',{staticClass:"mt-5",attrs:{"outlined":""}},[_c('v-card-text',[_c('div',[_c('v-icon',[_vm._v("mdi-numeric-1-circle-outline")]),_vm._v(" Vidéo Santé / Sécurité ")],1),_c('div',[_c('v-icon',[_vm._v("mdi-numeric-2-circle-outline")]),_vm._v(" ... ")],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }