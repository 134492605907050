<template>
  <div>
    <v-alert
      outlined
      color="primary"
      icon="mdi-information-outline"
      prominent
      border="left"
    >
      Téléchargez le
      <a href @click.prevent="downloadReport">rapport</a> complet des
      progressions.
    </v-alert>
    <v-card outlined class="pa-10">
      <v-card-title>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Rechercher"
          single-line
          hide-details
          outlined
          dense
          class="mb-6"
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="users"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        class="elevation-0"
        locale="fr-CH"
        :search="search"
        :footer-props="{
          itemsPerPageOptions: [10, 30, 50],
          itemsPerPageText: 'Élements par page',
        }"
      >
        <!-- eslint-disable-next-line vue/valid-v-slot justification: possible according to vuetify documentation -->
        <template v-slot:body="{ items }">
          <tbody class="text-left">
            <tr v-for="item in items" :key="item._id">
              <td>{{ item.lastname }}</td>
              <td>{{ item.firstname }}</td>
              <td>{{ item.email }}</td>
              <td>
                {{ item.role === "employee" ? "Employé.e" : "Étudiant.e" }}
              </td>
              <td>
                <v-icon
                  v-for="step in item.steps"
                  :color="step.updatedAt && step.checked ? 'success' : 'grey'"
                  :key="`${item._id}-slot-${step.number}`"
                  >{{ `mdi-numeric-${step.number + 1}-circle-outline` }}</v-icon
                >
              </td>
            </tr>
          </tbody>
        </template>
        <!-- eslint-disable-next-line vue/valid-v-slot justification: possible according to vuetify documentation -->
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }"
          >{{ pageStart }}-{{ pageStop }} de {{ itemsLength }}</template
        >
      </v-data-table>
    </v-card>
    <v-card outlined class="mt-5">
      <v-card-text>
        <div>
          <v-icon>mdi-numeric-1-circle-outline</v-icon>
          Vidéo Santé / Sécurité
        </div>
        <div>
          <v-icon>mdi-numeric-2-circle-outline</v-icon>
          ...
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>


<script>
import axios from "axios";
import { debounce } from "lodash";
import { parseISO, format } from "date-fns";
import fileDownload from "js-file-download";

export default {
  data: () => ({
    loading: false,
    search: "",
    users: [],
    total: 0,
    options: {},
    statuses: [],
    selectedFilieres: [],
    filieres: [],
    headers: [
      {
        text: "Nom",
        value: "lastname",
        sortable: true,
      },
      {
        text: "Prénom",
        value: "firstname",
        sortable: true,
      },
      {
        text: "E-mail",
        value: "email",
        sortable: true,
      },
      {
        text: "Role",
        value: "role",
        sortable: true,
      },
      {
        text: "Etapes",
        value: "steps",
        sortable: false,
      },
    ],
    parseISO,
    format,
  }),
  methods: {
    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/users?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}&search=${this.search}`,
      });
      this.loading = false;
      let { docs: items } = data;
      const total = data.totalDocs;
      return {
        items,
        total,
      };
    },
    resetFilters() {
      this.selectedFilieres = [];
    },
    async downloadReport() {
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/users/export`,
        responseType: "arraybuffer",
      });
      fileDownload(data, "users.xlsx");
    },
  },
  watch: {
    search: debounce(async function () {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      const { data } = await axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URI}/admin/users?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}&search=${this.search}`,
      });

      this.loading = false;
      let { docs: items } = data;
      const total = data.totalDocs;
      this.users = items;
      this.total = total;
    }, 500),
    options: {
      async handler() {
        const data = await this.getDataFromApi();
        this.users = data.items;
        this.total = data.total;
      },
      deep: true,
    },
  },
};
</script>