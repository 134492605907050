<template>
  <v-container>
    <v-row class="mb-0">
      <v-col>
        <users-table />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UsersTable from "@/components/UsersTable";
export default {
  name: "App",
  components: { UsersTable },
};
</script>

<style>
.v-speed-dial {
  position: absolute;
}
.v-btn--floating {
  position: relative;
}
</style>